.active{
    color: black !important;
    
}
.parent{
    padding-left: 30px;
    
}
.notActive{
    color:rgb(85, 80, 80) !important;
}